<template>
	<div>
		<!-- 图片裁切 -->
		<template v-if="isCropper">
			<el-upload class="avatar-uploader"
				:action="''" 
				:show-file-list="false"
				accept="mp4"
				:auto-upload="false"
				:on-change="changeUpload"
				>
					<!-- 上传图片 -->
					<img v-if="imgKey" :src="imgKey" style="width: 100px"/>
					<!-- 上传过程百分比 -->
					<el-progress v-else-if="percent!=0&&percent!=100" type="circle" :percentage="percent"></el-progress>
					<!-- 上传加号 -->
					<i v-else class="el-support el-icon-plus avatar-uploader-icon"></i>
			</el-upload>
			<template v-if="cropperWrap">
				<div class="cropper" :style="`width: ${width}px;height:${height}px;margin-bottom:10px`">
					<VueCropper
						ref="cropper"
						:img="option.img"
						:outputType="option.outputType"
						:info="true"
						:canMoveBox="option.canMoveBox"
						:autoCrop="option.autoCrop"
						:infoTrue="option.infoTrue"
						:fixedBox="option.fixedBox"
					></VueCropper>
				</div>
				<el-button size="small" type="primary" @click="finish">上传图片</el-button>
				<el-button size="small" @click="cropperWrap = false">取消上传</el-button>
			</template>
		</template>
		<!-- 不用裁切 -->
		<template v-else>
			<el-upload class="avatar-uploader" 
				:action="$constant.qnUpdata" 
				:data="{key:key, token:qnToken}" 
				:show-file-list="false"
				accept="mp4"
				:before-upload="beforeUpload" 
				:on-progress="onProgress"
				:on-success="imgUpSuccess"
				:on-error="imgUpError"
				>
					<!-- 上传图片 -->
					<img v-if="imgKey" :src="imgKey" style="width: 100px"/>
					<!-- 上传视频 -->
					<video v-else-if="videoKey" :src="videoKey" style="width: 50px" controls="controls"/>
					<!-- 上传过程百分比 -->
					<el-progress v-else-if="percent!=0&&percent!=100" type="circle" :percentage="percent"></el-progress>
					<!-- 上传加号 -->
					<i v-else class="el-support el-icon-plus avatar-uploader-icon"></i>
			</el-upload>
		</template>
	</div>
</template>

<script>
	import axios from 'axios'   //引入 axios
	import { getUploadToken } from '@/api/base'
	import { VueCropper } from 'vue-cropper'
	export default {
		name: 'upload',
		props: {
			isCropper: { // 是否裁切
				type: Boolean,
				default: false
			},
			width: { // 裁切宽度 = 想要宽度/0.8
				type: Number,
				default: 0
			},
			height: { // 裁切高度 = 想要高度/0.8
				type: Number,
				default: 0
			},
			imgKey: { // 上传得是图片
				type: String,
				default: ''
			},
			videoKey: { // 上传得是视频
				type: String,
				default: ''
			}
		},
		data() {
			return {
				cropperWrap: false,
				key: '',
				qnToken: '', // 七牛云token
				percent: 0, // 上传百分比
				fileinfo: {}, // 图片信息
				option: {
					img: '', // 裁剪图片的地址
					info: true, // 裁剪框的大小信息
					outputSize: 1, // 裁剪生成图片的质量
					outputType: 'jpeg', // 裁剪生成图片的格式
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: '80%', // 默认生成截图框宽度
					autoCropHeight: '80%', // 默认生成截图框高度
					fixedBox: true, // 固定截图框大小 不允许改变
					canMoveBox: false, // 截图框能否拖动
					infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
				},
			}
		},
		components: {
			VueCropper
		},
		methods: {
			// 设置基础信息
			async setBasics(file) {
				// 图片名
				let ext = file.name.split('.').pop();
				this.key = 'admin' + new Date().getTime() + '.' + ext;
				// token
				this.qnToken = localStorage.getItem('qn-token')
				if(!this.qnToken) {
					let {data: {upload_token: token}} = await getUploadToken()
					localStorage.setItem('qn-token', token)
					this.qnToken = localStorage.getItem('qn-token')
				}
			},
			/**
			 * 以下是不用裁切图片
			 */
			// 上传之前
			async beforeUpload(file) {
				console.log(file, '上传之前')
				await this.setBasics(file)
			},
			// 上传过程
			onProgress(e,file,fileList){
				this.percent = parseInt(e.percent)
				console.log('e',e.percent,'file',file,'filelist',fileList,'上传过程')
			},
			// 上传成功
			imgUpSuccess(res) {
				console.log(res, '上传成功')
				this.$emit('uploadSuccess', res)
			},
			// 上传失败
			imgUpError(res) {
				this.$message({ message: 'token已过期，请刷新页面或重新登录', type: 'info' });
			},
			/**
			 * 以下是裁切图片
			 */
			// 文件状态改变时 添加文件、上传成功和上传失败时都会被调用
			changeUpload(file, fileList) {
				this.cropperWrap = true
				this.fileinfo = file
				// 上传成功后将图片地址赋值给裁剪框显示图片
				console.log(file, fileList, '改变')
				this.$nextTick(() => {
					this.option.img = URL.createObjectURL(file.raw);
				})
			},
			// 上传
			finish() {
				this.$refs.cropper.getCropBlob(async (data) => {
					await this.setBasics(this.fileinfo)
					this.uploadImg(data)
				})
			},
			// 裁切图片上传
			uploadImg(data) {
				let formData = new FormData();
				formData.append("file", data);
				formData.append("key", this.key);
				formData.append("token", this.qnToken);
				axios.post(
					this.$constant.qnUpdata, 
					formData, 
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				).then(res => {
					this.cropperWrap = false
					this.$emit('uploadSuccess', res.data)
				}).catch(err=>{
					this.$message({ message: 'token已过期，请刷新页面或重新登录', type: 'info' });
				})
			}
		}
	}
</script>
<style lang="less">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.el-support {
	font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
</style>